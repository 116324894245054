import React, { useEffect, useState } from 'react';

function UserForm(props) {
    const [email, setEmail] = useState('');
    //const [telephone_number, setTelephoneNumber] = useState('');
    const [can_administer, setCanAdminister] = useState(false);
    const [can_edit_on_call, setCanEditOnCall] = useState(false);
    useEffect(() => {
        if (!props.loading && props.data) {
            setEmail(props.data.email);
            //setTelephoneNumber(props.data.telephone_number || '');
            setCanAdminister(props.data.can_administer);
            setCanEditOnCall(props.data.can_edit_on_call);
        }
    }, [props.data, props.loading]);

    return (
        <React.Fragment>
            {props.data ? (
                <h1>{props.data.email}</h1>
            ) : (
                <label>
                    Email
                    <input type="text" placeholder="Enter email address" value={email} onChange={event => setEmail(event.target.value)} disabled={props.loading} />
                </label>
            )}
            {/*
            <label>
                Telephone Number (Used for On Call Contact Flows)
                <input type="text" placeholder="Enter telephone number" value={telephone_number} onChange={event => setTelephoneNumber(event.target.value)} disabled={props.loading} />
            </label>
            */}
            <div className="grid-x align-middle paddle-container">
                <div className="cell auto padding-right-1">
                    <p className="hide-for-sr">⚙️ Can administer?</p>
                </div>
                <div className="cell shrink">
                    <div className="switch large">
                        <input className="switch-input" id="user_can_administer" type="checkbox" checked={can_administer} onChange={event => setCanAdminister(event.target.checked)} disabled={props.loading} />
                        <label className="switch-paddle" htmlFor="user_can_administer">
                            <span className="show-for-sr">Can administer?</span>
                            <span className="switch-active" aria-hidden="true">Yes</span>
                            <span className="switch-inactive" aria-hidden="true">No</span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="grid-x align-middle paddle-container">
                <div className="cell auto padding-right-1">
                    <p className="hide-for-sr">📞 Can edit on-call?</p>
                </div>
                <div className="cell shrink">
                    <div className="switch large">
                        <input className="switch-input" id="user_can_edit_on_call" type="checkbox" checked={can_edit_on_call} onChange={event => setCanEditOnCall(event.target.checked)} disabled={props.loading} />
                        <label className="switch-paddle" htmlFor="user_can_edit_on_call">
                            <span className="show-for-sr">Can edit on-call?</span>
                            <span className="switch-active" aria-hidden="true">Yes</span>
                            <span className="switch-inactive" aria-hidden="true">No</span>
                        </label>
                    </div>
                </div>
            </div>
            <button
                className="button primary"
                onClick={() => {
                    if (!email) {
                        props.onError('Email is required');
                        return;
                    }

                    props.onError(false);

                    props.onSubmit({
                        email,
                        //telephone_number: telephone_number || null,
                        can_administer,
                        can_edit_on_call,
                    });
                }}>
                {props.button_label ?? 'Save'}</button>
        </React.Fragment>
    );
}

export default UserForm;
