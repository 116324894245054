import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import Loader from '../../components/util/loader';
import ErrorMessage from '../../components/util/errormessage';
import { withAuth } from '../../components/util/auth';
import MatchForm from '../../components/match/form';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

const GET_MATCH_QUERY = gql`
query ($id: ID!, $next_token: String) {
    getMatch(id: $id) {
        id
        label
        home_name
        away_name
        status
        start_time
        hours_before
        hours_cover
        on_call
        second_on_call
    }
    getUserEmails(next_token: $next_token) {
        items {
            email
        }
        nextToken
    }
}
`

const UPDATE_MATCH_MUTATION = gql`
mutation ($id: ID!, $start_time: Int, $label: String, $hours_before: Int!, $hours_cover: Int!, $on_call: String, $second_on_call: String) {
    updateMatch(id: $id, start_time: $start_time, label: $label, hours_before: $hours_before, hours_cover: $hours_cover, on_call: $on_call, second_on_call: $second_on_call) {
        id
    }
}
`
function MatchEdit() {
    const navigate = useNavigate();
    const { id, year_month } = useParams();
    const [error, setError] = useState(false);
    const { data, error: getError, loading: getLoading, fetchMore } = useQuery(
        GET_MATCH_QUERY,
        {
            variables: {
                id,
                next_token: null,
            },
            fetchPolicy: 'cache-and-network'
        }
    );
    const [nextToken, setNextToken] = useState(null);
    useEffect(() => {
        // Renders happen all the time with Apollo for some reason, like when fetchMore called before it even finishes fetching
        // To avoid multiple fetches for same data, ensure we store the token we used for last fetchMore
        if (data?.getUserEmails?.nextToken && data.getUserEmails.nextToken !== nextToken) {
            setNextToken(data.getUserEmails.nextToken);
            fetchMore({
                variables: {
                    next_token: data.getUserEmails.nextToken
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    return {...prev, getUserEmails: {...prev.getUserEmails, items: [...prev.getUserEmails.items, ...fetchMoreResult.getUserEmails.items], nextToken: fetchMoreResult.getUserEmails.nextToken}};
                }
            });
        }
    }, [data, fetchMore, nextToken]);
    const [updateMatch, { error: putError, loading: putLoading }] = useMutation(UPDATE_MATCH_MUTATION);

    const loading = getLoading || putLoading;

    return (
        <div className="grid-container match-edit loaderparent">
            <Loader loading={loading} />
            <div className="grid-x grid-padding-y">
                <div className="cell">
                    <ErrorMessage enabled={error || getError || putError} message={error} />
                    {!loading && !data?.getMatch ? (
                        <React.Fragment>
                            <ErrorMessage enabled={true} message="Match not found" />
                            <Link className="button" to="/">Home</Link>
                        </React.Fragment>
                    ) : null}
                    <MatchForm
                        loading={loading}
                        data={data?.getMatch}
                        users={data?.getUserEmails}
                        onError={setError}
                        onSubmit={result => {
                            updateMatch({
                                variables: {
                                    ...result,
                                    id: data.getMatch.id,
                                }
                            }).then(() => {
                                navigate(year_month ? `/matches/view/${year_month}` : '/');
                            }, () => {
                                // Ignore errors in Promise - allow render to display it
                            });
                        }} />
                </div>
            </div>
        </div>
    );
}

export default withAuth(MatchEdit, 'edit_on_call');
