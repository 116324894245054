import React from 'react';
import { Link } from 'react-router-dom';

function AccessDenied() {
    return (
        <div className="grid-container notfound">
            <div className="grid-x">
                <div className="cell">
                    <p>Access denied</p>
                    <Link className="button" to="/">Home</Link>
                </div>
            </div>
        </div>
    );
}

export default AccessDenied;
