import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import Loader from '../components/util/loader';
import useToggle from '../components/util/toggle';
import ErrorMessage from '../components/util/errormessage';
import { useAuth, withAuth } from '../components/util/auth';
import { addHours, format, fromUnixTime, isAfter, isBefore } from 'date-fns'
import { Link, useParams } from 'react-router-dom';
import Buttons from '../components/home/buttons';
import './home.scss';

const GET_MATCHES_QUERY = gql`
query ($year_month: String!, $next_token: String) {
    config {
        team_ids
    }
    getMatches(year_month: $year_month, next_token: $next_token) {
        items {
            id
            label
            home_id
            home_name
            away_id
            away_name
            status
            start_time
            hours_before
            hours_cover
            on_call
            second_on_call
        }
        nextToken
    }
}
`
function Home() {
    const auth = useAuth();
    const [moreLoading, setMoreLoading] = useState(true);
    const { year_month: year_month_input } = useParams();
    const year_month = year_month_input ?? format(new Date(), 'yyyy-MM');
    const current_year = Number(year_month.split('-', 2)[0] ?? 1);
    const current_month = Number(year_month.split('-', 2)[1] ?? 1);
    const months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
    const isActualMonth = current_month === new Date().getMonth() + 1;
    const isActualYear = current_year === new Date().getFullYear();
    const showFilters = isActualMonth && isActualYear;
    const [showHistorical, toggleHistorical] = useToggle(!showFilters);
    const [nextToken, setNextToken] = useState(null);
    const { data, error, loading: getLoading, fetchMore } = useQuery(
        GET_MATCHES_QUERY,
        {
            variables: {
                year_month: year_month
            },
            fetchPolicy: 'cache-and-network',
            // When using cache-and-network - have to use cache-first if using fetchMore
            // otherwise the fetchMore update causes a network request again
            // Thus, prevent network requests after the first as a workaround
            // See: https://github.com/apollographql/apollo-client/issues/6916
            nextFetchPolicy: 'cache-first',
        }
    );
    useEffect(() => {
        // Renders happen all the time with Apollo for some reason, like when fetchMore called before it even finishes fetching
        // To avoid multiple fetches for same data, ensure we store the token we used for last fetchMore
        if (data?.getMatches?.nextToken && data.getMatches.nextToken !== nextToken) {
            setNextToken(data.getMatches.nextToken);
            fetchMore({
                variables: {
                    year_month: year_month,
                    next_token: data.getMatches.nextToken
                }
            });
        }
        if (data && !data?.getMatches?.nextToken) {
            setMoreLoading(false);
        }
    }, [year_month, data, fetchMore, nextToken]);

    const loading = moreLoading || getLoading;

    return (
        <div className="grid-container home loaderparent">
            <Loader loading={loading} />
            <div className="grid-x grid-padding-y">
                <div className="cell">
                    <ErrorMessage enabled={error} />
                    <h1>Matches</h1>
                    <p><Link to={`/matches/view/${current_year -1}-01`}>{current_year - 1}</Link> <strong>{current_year}</strong> <Link to={`/matches/view/${current_year + 1}-01`}>{current_year + 1}</Link></p>
                    <p>
                        {months.map((name, index) => (
                            <React.Fragment key={index}>
                                {' '}
                                {index + 1 === current_month ? (
                                    <strong>{name}</strong>
                                ) : (
                                    <Link to={`/matches/view/${current_year}-${String(index + 1).padStart(2, '0')}`}>{name}</Link>
                                )}
                            </React.Fragment>
                        ))}
                    </p>
                    <Buttons showAdd={auth.user.can_edit_on_call} showFilters={showFilters} showHistorical={showHistorical} toggleHistorical={toggleHistorical} />
                    <table className="stack hover striped">
                        <thead>
                            <tr>
                                <th>Match</th>
                                <th>Start Time</th>
                                {/* <th>On Call</th> */}
                                {auth.user.can_edit_on_call ? <th>Actions</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {data?.getMatches?.items.length ? data.getMatches.items.map(entry => {
                                if (!entry.id.startsWith('x_') && !data.config.team_ids.includes(entry.home_id) && !data.config.team_ids.includes(entry.away_id)) {
                                    return null;
                                }

                                let startDate = fromUnixTime(entry.start_time),
                                    coverStartDate = addHours(startDate, -1 * entry.hours_before),
                                    coverEndDate = addHours(coverStartDate, entry.hours_cover),
                                    inProgress = false,
                                    className = '', teamClassHome = '', teamClassAway = '', statusClass = '';

                                if (isBefore(coverStartDate, new Date()) && isAfter(coverEndDate, new Date())) {
                                    className = 'home__row--inprogress';
                                    inProgress = true;
                                } else if (entry.status === 'Postponed') {
                                    className = 'home__row--postponed';
                                } else if (isBefore(coverEndDate, new Date())) {
                                    className = 'home__row--historical';

                                    if (showFilters && !showHistorical) {
                                        className += ' home__row--hidden'
                                    }
                                }

                                if (data.config.team_ids.includes(entry.home_id)) {
                                    teamClassHome = 'home__team--client';
                                }
                                if (data.config.team_ids.includes(entry.away_id)) {
                                    teamClassAway = 'home__team--client';
                                }

                                if (entry.status) {
                                    statusClass = 'home_status--bad';
                                }

                                return (
                                    <tr key={entry.id} className={`home__row ${className}`}>
                                        <td>
                                            {entry.label || (
                                                <React.Fragment>
                                                    <span className={`home__team ${teamClassHome}`}>{entry.home_name}</span>{' v '}
                                                    <span className="show-for-large"><br /></span>
                                                    <span className={`home__team ${teamClassAway}`}>{entry.away_name}</span>
                                                </React.Fragment>
                                            )}
                                        </td>
                                        <td>
                                            {format(startDate, 'EEE do MMM, HH:mm')}<br />
                                            <span className={`home__status ${statusClass}`}>{entry.status}</span>
                                        </td>
                                        {/* <td>
                                            {!entry.on_call && !entry.second_on_call ? '-' : ''}
                                            {entry.on_call ? <React.Fragment>📲 {entry.on_call}</React.Fragment> : ''}
                                            {entry.second_on_call ? <React.Fragment><br />🔧 {entry.second_on_call}</React.Fragment> : ''}
                                            {inProgress ? <React.Fragment><br />🚧 In Progress</React.Fragment> : ''}
                                        </td> */}
                                        {auth.user.can_edit_on_call ? (
                                            <td>
                                                <Link to={`/matches/edit/${entry.id}/return/${year_month}`}>Edit</Link> {entry.id.startsWith('x_') ? <Link to={`/matches/delete/${entry.id}/return/${year_month}`}>Delete</Link> : null}
                                            </td>
                                        ) : null}
                                    </tr>
                                );
                            }) : (
                                <tr>
                                    <td colSpan={auth.user.can_edit_on_call ? 4 : 3}>{loading ? '' : 'No matches found'}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <Buttons showAdd={auth.user.can_edit_on_call} showFilters={showFilters} showHistorical={showHistorical} toggleHistorical={toggleHistorical} />
                </div>
            </div>
        </div>
    );
}

export default withAuth(Home);
