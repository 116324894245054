import React, { useEffect, useState } from 'react';
import { format, fromUnixTime, getUnixTime } from 'date-fns';

function MatchForm(props) {
    const today = new Date();
    const [start_time_year, setStartTimeYear] = useState(today.getFullYear());
    const [start_time_month, setStartTimeMonth] = useState(today.getMonth() + 1);
    const [start_time_day, setStartTimeDay] = useState(today.getDate());
    const [start_time_hour, setStartTimeHour] = useState(today.getHours());
    const [start_time_minute, setStartTimeMinute] = useState(0);
    const [label, setLabel] = useState('');
    const [hours_before, setHoursBefore] = useState('0');
    const [hours_cover, setHoursCover] = useState('1');
    const [on_call, setOnCall] = useState('');
    const [second_on_call, setSecondOnCall] = useState('');
    useEffect(() => {
        if (!props.loading && props.data) {
            const start_time = fromUnixTime(props.data.start_time);
            setStartTimeYear(start_time.getFullYear());
            setStartTimeMonth(start_time.getMonth() + 1);
            setStartTimeDay(start_time.getDate());
            setStartTimeHour(start_time.getHours());
            setStartTimeMinute(start_time.getMinutes());
            setLabel(props.data.label || '');
            setHoursBefore(props.data.hours_before || (props.data.hours_before === '0' ? '0' : ''));
            setHoursCover(props.data.hours_cover || (props.data.hours_cover === '0' ? '0' : ''));
            setOnCall(props.data.on_call || '');
            setSecondOnCall(props.data.second_on_call || '');
        }
    }, [props.data, props.loading]);

    const days_in_month = Number(start_time_month) === 2 ? (start_time_year % 4 === 0 ? (start_time_year % 100 !== 0 || start_time_year % 400 === 0 ? 29 : 38) : 28) : ([9, 4, 6, 11].includes(Number(start_time_month)) ? 30 : 31);

    // Custom entries with custom label start with x_ (used for things like office cover) - everything else is fixture
    return (
        <React.Fragment>
            {props.data && !props.data.id.startsWith('x_') ? (
                <React.Fragment>
                    <h1>{props.data.home_name} v {props.data.away_name}</h1>
                    <p>Start time: {format(new Date(props.data.start_time * 1000), 'do MMMM HH:mm')}</p>
                    {props.data.status ? <p>Status: {props.data.status}</p> : null}
                </React.Fragment>
            ) : null}
            <label>
                Custom Label
                <input type="text" placeholder="Enter label" value={label} onChange={event => setLabel(event.target.value)} disabled={props.loading} />
            </label>
            {!props.data || props.data.id.startsWith('x_') ? (
                <React.Fragment>
                    <label>
                        Day
                        <select value={start_time_day} onChange={event => setStartTimeDay(event.target.value)}>
                            {'-'.repeat(days_in_month - 1).split('-').map((_, index) => {
                                return <option key={index} value={index + 1}>{index + 1}</option>;
                            })}
                        </select>
                    </label>
                    <label>
                        Month
                        <select value={start_time_month} onChange={event => setStartTimeMonth(event.target.value)}>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                    </label>
                    <label>
                        Year
                        <select value={start_time_year} onChange={event => setStartTimeYear(event.target.value)}>
                            {'-'.repeat(99).split('-').map((_, index) => {
                                return <option key={index} value={1970 + index}>{1970 + index}</option>;
                            })}
                        </select>
                    </label>
                    <label>
                        Hour
                        <select value={start_time_hour} onChange={event => setStartTimeHour(event.target.value)}>
                            {'-'.repeat(24).split('-').map((_, index) => {
                                return <option key={index} value={index}>{String(index).padStart(2, '0')}</option>;
                            })}
                        </select>
                    </label>
                    <label>
                        Minute
                        <select value={start_time_minute} onChange={event => setStartTimeMinute(event.target.value)}>
                            {'-'.repeat(60).split('-').map((_, index) => {
                                return <option key={index} value={index}>{String(index).padStart(2, '0')}</option>;
                            })}
                        </select>
                    </label>
                </React.Fragment>
            ) : null}
            {/*
            <label>
                Hours Before Start Needing Cover
                <input type="number" value={hours_before} onChange={event => setHoursBefore(event.target.value)} disabled={props.loading} />
            </label>
            <label>
                Hours Covered
                <input type="number" value={hours_cover} onChange={event => setHoursCover(event.target.value)} disabled={props.loading} />
            </label>
            <label>
                On Call
                <select value={on_call || ''} onChange={event => setOnCall(event.target.value || null)} disabled={props.loading}>
                    <option value="">- Nobody -</option>
                    {props.users?.items.map(user => (
                        <option key={user.email} value={user.email}>{user.email}</option>
                    ))}
                </select>
            </label>
            <label>
                Second On Call
                <select value={second_on_call || ''} onChange={event => setSecondOnCall(event.target.value || null)} disabled={props.loading}>
                    <option value="">- Nobody -</option>
                    {props.users?.items.map(user => (
                        <option key={user.email} value={user.email}>{user.email}</option>
                    ))}
                </select>
            </label>
            */}
            <button
                className="button primary"
                onClick={() => {
                    if (!hours_before && hours_before !== '0') {
                        props.onError('Hours Before is required');
                        return;
                    }
                    if (!hours_cover && hours_cover !== '0') {
                        props.onError('Hours Covered is required');
                        return;
                    }

                    props.onError(false);

                    let start_time_data = {};
                    if (!props.data || props.data.id.startsWith('x_')) {
                        const start_time = new Date(start_time_year, start_time_month - 1, start_time_day, start_time_hour, start_time_minute, 0, 0);
                        start_time_data.start_time = getUnixTime(start_time);
                    }
                    props.onSubmit({
                        label: label || null,
                        ...start_time_data,
                        hours_before,
                        hours_cover,
                        on_call,
                        second_on_call,
                    });
                }}>
                {props.button_label ?? 'Save'}</button>
        </React.Fragment>
    );
}

export default MatchForm;
