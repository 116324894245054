import React, { useEffect } from 'react';
import Loader from '../../components/util/loader';
import { useNavigate } from 'react-router';
import { useLogout, useAuth } from '../../components/util/auth';
import './logout';

function Logout() {
    const navigate = useNavigate();
    const logout = useLogout();
    const auth = useAuth();

    useEffect(() => {
        if (!auth.username) {
            navigate('/');
            return;
        }

        logout();
    }, [navigate, auth, logout]);

    return (
        <div className="logout">
            <Loader loading={true} />
        </div>
    );
}

export default Logout;
