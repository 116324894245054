import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import Loader from '../../components/util/loader';
import ErrorMessage from '../../components/util/errormessage';
import { withAuth } from '../../components/util/auth';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import UserForm from '../../components/user/form';

const GET_USER_QUERY = gql`
query ($email: ID!) {
    getUser(email: $email) {
        email
        telephone_number
        can_administer
        can_edit_on_call
    }
}
`

const UPDATE_USER_MUTATION = gql`
mutation ($email: ID!, $telephone_number: String, $can_administer: Boolean!, $can_edit_on_call: Boolean!) {
    updateUser(email: $email, telephone_number: $telephone_number, can_administer: $can_administer, can_edit_on_call: $can_edit_on_call) {
        email
    }
}
`

function UserEdit() {
    const navigate = useNavigate();
    const { email } = useParams();
    const [error, setError] = useState(false);
    const { data, error: getError, loading: getLoading } = useQuery(
        GET_USER_QUERY,
        {
            variables: {
                email: decodeURIComponent(email)
            },
            fetchPolicy: 'cache-and-network'
        }
    );
    const [updateUser, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_USER_MUTATION);

    const loading = getLoading || updateLoading;

    return (
        <div className="grid-container match-edit loaderparent">
            <Loader loading={loading} />
            <div className="grid-x grid-padding-y">
                <div className="cell">
                    <ErrorMessage enabled={error || getError || updateError} message={error} />
                    {!loading && !error && !data?.getUser ? (
                        <React.Fragment>
                            <ErrorMessage enabled={true} message="User not found" />
                            <Link className="button" to="/users">Users</Link>
                        </React.Fragment>
                    ) : null}
                    {data?.getUser ? (
                        <UserForm
                            loading={loading}
                            data={data.getUser}
                            onError={setError}
                            onSubmit={result => {
                                updateUser({
                                    variables: {
                                        ...result,
                                        email: data.getUser.email
                                    }
                                }).then(() => {
                                    navigate('/users');
                                }, () => {
                                    // Ignore errors in Promise - allow render to display it
                                });
                            }} />
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default withAuth(UserEdit, 'administer');
