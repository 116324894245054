import React, { useState } from 'react';
import { Routes, Route } from 'react-router';
import Home from './pages/home';
import Header from './components/header';
import Navigation from './components/navigation';
import Login from './pages/auth/login';
import Logout from './pages/auth/logout';
import Config from './pages/config';
import Users from './pages/users';
import UserAdd from './pages/user/add';
import UserEdit from './pages/user/edit';
import UserDelete from './pages/user/delete';
import UserReset from './pages/user/reset';
import MatchAdd from './pages/match/add';
import MatchEdit from './pages/match/edit';
import MatchDelete from './pages/match/delete';
import NotFound from './pages/notfound';
import './app.scss';
import { useAuth } from './components/util/auth';

function App() {
    const [showNavigation, setShowNavigation] = useState(false);
    const auth = useAuth();

    return (
        <div className={`app ${auth.user ? 'app--navigatable' : ''}`}>
            <Header showNavigation={showNavigation} setShowNavigation={setShowNavigation} />
            <div className="app__layout">
                {auth.user ? <Navigation showNavigation={showNavigation} /> : null}
                <div className="app__body">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/auth/login" element={<Login />} />
                        <Route path="/auth/logout" element={<Logout />} />
                        <Route path="/config" element={<Config />} />
                        <Route path="/matches/add" element={<MatchAdd />} />
                        <Route path="/matches/view/:year_month" element={<Home />} />
                        <Route path="/matches/add/return/:year_month" element={<MatchAdd />} />
                        <Route path="/matches/delete/:id" element={<MatchDelete />} />
                        <Route path="/matches/delete/:id/return/:year_month" element={<MatchDelete />} />
                        <Route path="/matches/edit/:id" element={<MatchEdit />} />
                        <Route path="/matches/edit/:id/return/:year_month" element={<MatchEdit />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/users/add" element={<UserAdd />} />
                        <Route path="/users/delete/:email" element={<UserDelete />} />
                        <Route path="/users/edit/:email" element={<UserEdit />} />
                        <Route path="/users/reset/:email" element={<UserReset />} />
                        <Route element={<NotFound />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default App;
