import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <div className="grid-container notfound">
            <div className="grid-x">
                <div className="cell">
                    <p>Page not found</p>
                    <Link className="button" to="/">Home</Link>
                </div>
            </div>
        </div>
    );
}

export default NotFound;
