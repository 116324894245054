import React, { useCallback, useContext } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, createHttpLink } from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

// Retrieved from CloudFormation deployment after deployment
let uri;
switch (window.location.hostname) {
case 'admin.clubcast.co.uk':
    // Prod
    uri = 'https://pkivwp4hhrhcfmnmkoeouxcqju.appsync-api.eu-west-1.amazonaws.com/graphql';
    break;
default:
    // Dev
    uri = 'https://sbpv3nhfxran5g3twmaapxlqiq.appsync-api.eu-west-1.amazonaws.com/graphql';
    break;
}

let globalAccessToken;

const httpLink = createHttpLink({ uri });

const cache = new InMemoryCache({
    typePolicies: {
        User: {
            keyFields: ['email'],
        },
        UserLimited: {
            keyFields: ['email'],
        },
        Query: {
            fields: {
                config: {
                    merge(existing, incoming) {
                        return incoming;
                    }
                },
                getMatches: {
                    keyArgs: ['year_month'],
                    merge(existing, incoming, { readField, args }) {
                        // args can be null
                        const { next_token } = args || {};
                        if (process.env.NODE_ENV === 'development') {
                            console.log('Merging new getMatches response', incoming, existing);
                        }
                        if (!existing || !next_token) {
                            // TODO: We currently reset when we detect first page load because we can't detect which items need removing when future next_tokens take place
                            // Ideally we would have a "page" argument so we can remove items that are in that page, but then we don't know when last page hits to remove extras!
                            // So we will, for now, have a flicker for page 2 onwards since only page 1 will remain in cache through loads
                            return incoming;
                        }
                        // Beware of object references, use readField to access fields as it will work no matter if a Reference to cache or not
                        let existingIds = readField('items', existing).map(item => readField('id', item));
                        return { ...existing, nextToken: readField('nextToken', incoming), items: [...readField('items', existing), ...readField('items', incoming).filter(item => !existingIds.includes(readField('id', item)))] };
                    }
                },
                getUsers: {
                    keyArgs: false,
                    merge(existing, incoming, { readField, args }) {
                        // args can be null
                        const { next_token } = args || {};
                        if (process.env.NODE_ENV === 'development') {
                            console.log('Merging new getUsers response', incoming, existing);
                        }
                        if (!existing || !next_token) {
                            // TODO: We currently reset when we detect first page load because we can't detect which items need removing when future next_tokens take place
                            // Ideally we would have a "page" argument so we can remove items that are in that page, but then we don't know when last page hits to remove extras!
                            // So we will, for now, have a flicker for page 2 onwards since only page 1 will remain in cache through loads
                            return incoming;
                        }
                        // Beware of object references, use readField to access fields as it will work no matter if a Reference to cache or not
                        let existingIds = readField('items', existing).map(item => readField('email', item));
                        return { ...existing, nextToken: readField('nextToken', incoming), items: [...readField('items', existing), ...readField('items', incoming).filter(item => !existingIds.includes(readField('email', item)))] };
                    }
                }
            }
        }
    }
});

const link = ApolloLink.from([
    createAuthLink({
        url: uri,
        region: 'eu-west-1',
        auth: {
            type: 'OPENID_CONNECT',
            jwtToken: () => {
                return globalAccessToken;
            },
        },
    }),
    createSubscriptionHandshakeLink(
        uri,
        httpLink
    )
]);

const client = new ApolloClient({
    link,
    cache
});

const GraphqlContext = React.createContext();

export function useAccessTokenCallback() {
    const context = useContext(GraphqlContext);
    return context.setAccessToken;
}

export function GraphqlProvider(props) {
    const setAccessToken = useCallback(accessToken => {
        globalAccessToken = accessToken;
    }, []);

    return (
        <ApolloProvider client={client}>
            <GraphqlContext.Provider value={{setAccessToken}}>
                {props.children}
            </GraphqlContext.Provider>
        </ApolloProvider>
    );
}
