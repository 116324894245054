import React from 'react';
import { withAuth } from '../../components/util/auth';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import Loader from '../../components/util/loader';
import ErrorMessage from '../../components/util/errormessage';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

const GET_MATCH_QUERY = gql`
query ($id: ID!) {
    getMatch(id: $id) {
        id
        label
    }
}
`

const DELETE_MATCH_MUTATION = gql`
mutation($id: ID!) {
    deleteMatch(id: $id) {
        id
    }
}
`

function MatchDelete() {
    const { id, year_month } = useParams();
    const navigate = useNavigate();
    const { data: getData, error } = useQuery(
        GET_MATCH_QUERY,
        {
            fetchPolicy: 'network-only',
            variables: {
                id: decodeURIComponent(id)
            }
        }
    );
    const [deleteMatch, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_MATCH_MUTATION);

    const loading = !getData || deleteLoading;
    // Only custom matches can be deleted - GraphQL server checks too in delete so can't bypass this
    const data = getData?.getMatch?.id.startsWith('x_') ? getData : null;
    return (
        <div className="grid-container quizquestiondelete loaderparent">
            <Loader loading={loading} />
            <ErrorMessage enabled={error || deleteError} />
            {!loading && !error && !data?.getMatch ? (
                <React.Fragment>
                    <ErrorMessage enabled={true} message="Match not found" />
                    <Link className="button" to="/">Home</Link>
                </React.Fragment>
            ) : null}
            {data?.getMatch ? (
                <div className="grid-x">
                    <div className="cell">
                        <p>Are you sure you want to delete this match?</p>
                        <p><i>{data?.getMatch.label}</i></p>
                    </div>
                    <div className="cell">
                        <button
                            className="button"
                            disabled={loading || error}
                            onClick={() => {
                                deleteMatch({
                                    variables: {
                                        id: decodeURIComponent(id),
                                    }
                                }).then(() => {
                                    navigate(year_month ? `/matches/view/${year_month}` : '/');
                                }).catch(() => {});
                            }}>
                            Delete Match</button>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default withAuth(MatchDelete, 'edit_on_call');
