import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import Loader from '../components/util/loader';
import ErrorMessage from '../components/util/errormessage';
import { withAuth } from '../components/util/auth';

const GET_CONFIG_QUERY = gql`
query {
    config {
        team_ids
        notifications
    }
}
`

const PUT_CONFIG_MUTATION = gql`
mutation ($team_ids: [String!]!, $notifications: String!) {
    putConfig(team_ids: $team_ids, notifications: $notifications) {
        notifications
    }
}
`

function Config() {
    const [newTeamId, setNewTeamId] = useState('');
    const [notifications, setNotifications] = useState('');
    const { data, error, refetch, loading: getLoading } = useQuery(
        GET_CONFIG_QUERY,
        {
            fetchPolicy: 'cache-and-network'
        }
    );
    const [putConfig, { loading: putLoading, error: putError }] = useMutation(PUT_CONFIG_MUTATION, { onCompleted: () => refetch() });
    const loading = getLoading || putLoading;
    useEffect(() => {
        if (!loading && data) {
            setNotifications(data.config.notifications);
        }
    }, [loading, data]);

    return (
        <div className="grid-container home loaderparent">
            <Loader loading={loading} />
            <div className="grid-x grid-padding-y">
                <div className="cell">
                    <ErrorMessage enabled={error || putError} />
                    <h1>Configuration</h1>
                    <h2>Settings</h2>
                    <label>
                        Notification emails (comma-separated)
                        <input type="text" value={notifications} onChange={event => setNotifications(event.target.value)} disabled={loading} />
                    </label>
                    <button
                        className="button primary"
                        onClick={() => {
                            putConfig({
                                variables: {
                                    team_ids: data.config.team_ids,
                                    notifications,
                                }
                            }).then(() => {}, () => {});
                        }}>
                        Save</button>
                    <h2>Team IDs</h2>
                    <ul>
                        {data?.config?.team_ids.length ? data.config.team_ids.map((team_id, index) => {
                            return (
                                <li key={index}>
                                    {team_id} - {' '}
                                    <button
                                        onClick={() => {
                                            putConfig({
                                                variables: {
                                                    team_ids: data.config.team_ids.filter(entry => entry !== team_id),
                                                    notifications: data.config.notifications,
                                                }
                                            }).then(() => {}, () => {});
                                        }}>
                                        Delete</button>
                                </li>
                            );
                        }) : (
                            <li>{loading ? '' : 'No team IDs are configured'}</li>
                        )}
                    </ul>
                    <label>
                        Add team ID
                        <input type="text" placeholder="Enter team ID" value={newTeamId} onChange={event => setNewTeamId(event.target.value)} disabled={loading} />
                    </label>
                    <button
                        className="button primary"
                        onClick={() => {
                            putConfig({
                                variables: {
                                    team_ids: [...data.config.team_ids, newTeamId].sort(),
                                    notifications: data.config.notifications,
                                }
                            }).then(() => {
                                setNewTeamId('');
                                refetch();
                            }, () => {});
                        }}>
                        Add</button>
                </div>
            </div>
        </div>
    );
}

export default withAuth(Config, 'administer');
