import React from 'react';
import { useAuth, useAuthError, useLogin } from '../../components/util/auth';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom';
import ErrorMessage from '../../components/util/errormessage';
import './login.scss';

function Login() {
    const params = useParams();
    const auth = useAuth();
    const authError = useAuthError();
    const login = useLogin();

    if (auth.loading) {
        return null;
    }

    if (auth.user) {
        if (process.env.NODE_ENV === 'development') {
            console.log('Navigating to target now we are logged in', params.return);
        }
        const target = params.return ? params.return : '/';
        return <Navigate to={target} />;
    }

    return (
        <div className="login grid-container">
            <div className="grid-x grid-margin-x">
                <div className="cell">
                    {auth.username ? (
                        <ErrorMessage enabled={true} message="You are not currently authorized to access this portal." />
                    ) : (
                        <>
                            <ErrorMessage enabled={authError} message="An authentication error has occurred." />
                            <p>Please login with your account to continue.</p>
                            <p><button onClick={login}>Login</button></p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Login;
