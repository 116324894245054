import React from 'react';

function ErrorMessage(props) {
    if (!props.enabled) {
        return null;
    }

    if (props.enabled !== true) {
        console.error(props.enabled);
    }

    return (
        <div className="callout alert">
            <p>{props.message || 'An unexpected error has occurred. Please try again later.'}</p>
        </div>
    );
}

export default ErrorMessage;
