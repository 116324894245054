import { Link } from 'react-router-dom';

export default function Buttons(props) {
    const { showAdd, showFilters, showHistorical, toggleHistorical } = props;
    return (
        <div>
            {showAdd ? <Link to="/matches/add" className="button primary">Add</Link> : null}
            {showFilters ?
                <Link to="" onClick={toggleHistorical} className="button primary">
                    {showHistorical ? 'Hide completed' : 'Show completed'}
                </Link>
            : null}
        </div>
    );
}
