import React from 'react';
import { Link } from 'react-router-dom';
import './navigation.scss';
import { useAuth } from './util/auth';

function Navigation(props) {
    const auth = useAuth();

    return (
        <nav className={props.showNavigation ? 'navigation navigation--visible' : 'navigation'}>
            <div className="grid-x grid-padding-y">
                <div className="cell">
                    <ul className="vertical menu">
                        <li><Link to="/">Matches</Link></li>
                        {auth.user?.can_administer ? <li><Link to="/users">Users</Link></li> : null}
                        {auth.user?.can_administer ? <li><Link to="/config">Configuration</Link></li> : null}
                        <li><Link className="hide-for-medium" to="/auth/logout">Logout</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navigation;
