import React from 'react';
import { withAuth } from '../../components/util/auth';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import Loader from '../../components/util/loader';
import ErrorMessage from '../../components/util/errormessage';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

const GET_USER_QUERY = gql`
query ($email: ID!) {
    getUser(email: $email) {
        email
    }
}
`

const RESET_USER_MUTATION = gql`
mutation ($email: ID!) {
    resetUser(email: $email) {
        email
    }
}
`

function UserReset() {
    const { email } = useParams();
    const navigate = useNavigate();
    const { data, error } = useQuery(
        GET_USER_QUERY,
        {
            fetchPolicy: 'network-only',
            variables: {
                email: decodeURIComponent(email),
            }
        }
    );
    const [resetUser, { error: resetError, loading: resetLoading }] = useMutation(RESET_USER_MUTATION);

    let loading = !data || resetLoading;
    return (
        <div className="grid-container quizquestiondelete loaderparent">
            <Loader loading={loading} />
            <ErrorMessage enabled={error || resetError} />
            {!loading && !error && !data?.getUser ? (
                <React.Fragment>
                    <ErrorMessage enabled={true} message="User not found" />
                    <Link className="button" to="/users">Users</Link>
                </React.Fragment>
            ) : null}
            {data?.getUser ? (
                <div className="grid-x">
                    <div className="cell">
                        <p>Are you sure you want to reset this user? This will disassociate the user from their login and turn it back into an invitation. Useful if the IdP changes and user IDs are different.</p>
                        <p><i>{data?.getUser.email}</i></p>
                    </div>
                    <div className="cell">
                        <button
                            className="button"
                            disabled={loading || error}
                            onClick={() => {
                                resetUser({
                                    variables: {
                                        email,
                                    }
                                }).then(() => {
                                    navigate('/users');
                                }).catch(() => {});
                            }}>
                            Reset User</button>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default withAuth(UserReset, 'administer');
