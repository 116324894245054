import React from 'react';
import './loader.scss';

function Loader(props) {
    if (!props.loading) {
        return null;
    }

    return (
        <div className="loader">
            <div className="loader__inner">
                <img src="/clubcast.png" alt="Loading..." />
            </div>
        </div>
    )
}

export default Loader;
