import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import App from './app';
import { Auth } from './components/util/auth';
import { GraphqlProvider } from './components/util/graphql';

// Do not use <React.StrictMode> until Apollo Client works well with it, currently when editing things it reports can't perform a state update on unmounted, yet we are only using apollo react hooks
function render(Component) {
    ReactDOM.render(
        <GraphqlProvider>
            <Auth>
                <BrowserRouter>
                    <Component />
                </BrowserRouter>
            </Auth>
        </GraphqlProvider>,
        document.getElementById('root')
    );
}

render(App);

if (module.hot) {
    module.hot.accept('./app', () => {
        const Component = require('./app').default;
        render(Component);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
