import React, { useRef, useLayoutEffect } from 'react';
import jQuery from 'jquery';
import { Sticky } from 'foundation-sites';
import './header.scss';
import { useAuth } from './util/auth';
import { Link, useLocation } from 'react-router-dom';

function Header(props) {
    const stickyRef = useRef();
    const auth = useAuth();
    const location = useLocation();

    useLayoutEffect(() => {
        if (!stickyRef.current) {
            return;
        }
        let currentScrollHeight = document.documentElement.scrollHeight;
        const $element = jQuery(stickyRef.current);
        const sticky = new Sticky($element);
        // Workaround for Triggers not supporting dynamically added components
        const $window = jQuery(window);
        $window.on('resize.header', () => {
            $element.trigger('resizeme.zf.trigger');
        });
        const mutationObserver = new MutationObserver(() => {
            // Trigger resize me event so the sticky header recalculates when the scroll height changes
            if (document.documentElement.scrollHeight !== currentScrollHeight) {
                currentScrollHeight = document.documentElement.scrollHeight;
                $element.trigger('resizeme.zf.trigger');
            }
        });
        mutationObserver.observe(document.documentElement, { attributes: true, childList: true, subtree: true });
        return () => {
            mutationObserver.disconnect();
            $window.off('resize.header');
            sticky.destroy();
        };
    }, [stickyRef]);

    return (
        <div data-sticky-container className="header">
            {/* data-top-anchor setting to root:top fixes some issues when Safari bounces when throwing to top */}
            <header ref={stickyRef} className="sticky" data-sticky data-sticky-on="small" data-top-anchor="root:top" data-margin-top="0">
                <div className="header__inner title-bar">
                    <div className="title-bar-left">
                        <div className="grid-x grid-margin-x align-middle">
                            <div className="cell auto header__logo">
                                <Link to="/"><img src="/clubcast.png" alt="Clubcast" /></Link> Administration
                            </div>
                            <div className="cell auto shrink header__profile">
                                <div className="header__profile-log hide-for-small-only">
                                    {auth.username ? (
                                        <React.Fragment>
                                            <Link to="/auth/logout">Logout</Link>
                                        </React.Fragment>
                                    ) : (
                                        location.pathname !== '/auth/login' ? <Link to="/auth/login">Login</Link> : null
                                    )}
                                </div>
                                {auth.user ? (
                                    <div className="header__profile-nav hide-for-medium">
                                        <button className="menu-icon" type="button" onClick={() => props.setShowNavigation(!props.showNavigation)}></button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Header;
