import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import Loader from '../../components/util/loader';
import ErrorMessage from '../../components/util/errormessage';
import { withAuth } from '../../components/util/auth';
import { useNavigate } from 'react-router';
import MatchForm from '../../components/match/form';

const GET_USERS_QUERY = gql`
query ($next_token: String) {
    getUserEmails(next_token: $next_token) {
        items {
            email
        }
        nextToken
    }
}
`

const PUT_MATCH_MUTATION = gql`
mutation ($start_time: Int!, $label: String, $hours_before: Int!, $hours_cover: Int!, $on_call: String, $second_on_call: String) {
    putMatch(start_time: $start_time, label: $label, hours_before: $hours_before, hours_cover: $hours_cover, on_call: $on_call, second_on_call: $second_on_call) {
        id
    }
}
`

function MatchAdd() {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const { data, error: getError, loading: getLoading, fetchMore } = useQuery(
        GET_USERS_QUERY,
        {
            variables: {
                next_token: null,
            },
            fetchPolicy: 'cache-and-network'
        }
    );
    const [nextToken, setNextToken] = useState(null);
    useEffect(() => {
        // Renders happen all the time with Apollo for some reason, like when fetchMore called before it even finishes fetching
        // To avoid multiple fetches for same data, ensure we store the token we used for last fetchMore
        if (data?.getUserEmails?.nextToken && data.getUserEmails.nextToken !== nextToken) {
            setNextToken(data.getUserEmails.nextToken);
            fetchMore({
                variables: {
                    next_token: data.getUserEmails.nextToken
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    return {...prev, getUserEmails: {...prev.getUserEmails, items: [...prev.getUserEmails.items, ...fetchMoreResult.getUserEmails.items], nextToken: fetchMoreResult.getUserEmails.nextToken}};
                }
            });
        }
    }, [data, fetchMore, nextToken]);
    const [putMatch, { error: putError, loading: putLoading }] = useMutation(PUT_MATCH_MUTATION);

    const loading = getLoading || putLoading;

    return (
        <div className="grid-container match-edit loaderparent">
            <Loader loading={loading} />
            <div className="grid-x grid-padding-y">
                <div className="cell">
                    <ErrorMessage enabled={error || getError || putError} message={error} />
                    <MatchForm
                        loading={loading}
                        button-label="Add"
                        users={data?.getUserEmails}
                        onError={setError}
                        onSubmit={result => {
                            const start_date = new Date(result.start_time * 1000);
                            const year_month = `${start_date.getFullYear()}-${String(start_date.getUTCMonth() + 1).padStart(2, '0')}`;
                            putMatch({
                                variables: result
                            }).then(response => {
                                // TODO: Implement some layoutEffect on the home page to scan for it and jump to it but once only during loading cycle
                                navigate(year_month ? `/matches/view/${year_month}#${response.data.putMatch.id}` : '/');
                            }, () => {
                                // Ignore errors in Promise - allow render to display it
                            });
                        }} />
                </div>
            </div>
        </div>
    );
}

export default withAuth(MatchAdd, 'edit_on_call');
