import React from 'react';
import { withAuth } from '../../components/util/auth';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import Loader from '../../components/util/loader';
import ErrorMessage from '../../components/util/errormessage';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

const GET_USER_QUERY = gql`
query ($email: ID!) {
    getUser(email: $email) {
        email
    }
}
`

const DELETE_USER_MUTATION = gql`
mutation($email: ID!) {
    deleteUser(email: $email) {
        email
    }
}
`

function UserDelete() {
    const { email } = useParams();
    const navigate = useNavigate();
    const { data, error } = useQuery(
        GET_USER_QUERY,
        {
            fetchPolicy: 'network-only',
            variables: {
                email: decodeURIComponent(email),
            }
        }
    );
    const [deleteUser, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_USER_MUTATION);

    let loading = !data || deleteLoading;
    return (
        <div className="grid-container quizquestiondelete loaderparent">
            <Loader loading={loading} />
            <ErrorMessage enabled={error || deleteError} />
            {!loading && !error && !data?.getUser ? (
                <React.Fragment>
                    <ErrorMessage enabled={true} message="User not found" />
                    <Link className="button" to="/users">Users</Link>
                </React.Fragment>
            ) : null}
            {data?.getUser ? (
                <div className="grid-x">
                    <div className="cell">
                        <p>Are you sure you want to delete this user?</p>
                        <p><i>{data?.getUser.email}</i></p>
                    </div>
                    <div className="cell">
                        <button
                            className="button"
                            disabled={loading || error}
                            onClick={() => {
                                deleteUser({
                                    variables: {
                                        email,
                                    }
                                }).then(() => {
                                    navigate('/users');
                                }).catch(() => {});
                            }}>
                            Delete User</button>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default withAuth(UserDelete, 'administer');
