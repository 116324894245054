import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { gql } from '@apollo/client';
import { MsalProvider, useAccount, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { PublicClientApplication, EventType, InteractionType, InteractionRequiredAuthError, LogLevel, InteractionStatus } from '@azure/msal-browser';
import Loader from './loader';
import './auth.scss';
import AccessDenied from '../../pages/accessdenied';
import { useAccessTokenCallback } from './graphql';
import { differenceInMilliseconds, subSeconds } from 'date-fns';

// Retrieved from Azure App Registrations
// Be sure to configure a "access" scope under "app://admin.clubcast.co.uk/access" for permission to admin features
// Be sure to edit manifest to set accessTokenAcceptedVersion to integer 2
// Be sure to remove User.read graph scope as that will force a v1 token, we need v2
// Be sure to add email, openid, profile, offline_access and the access one above as scopes
let clientId, authority, scopes;
switch (window.location.hostname) {
case 'admin.clubcast.co.uk':
    // Prod
    clientId = 'ed572db8-292f-4fbe-829d-88f4263b81ba';
    authority = 'https://login.microsoftonline.com/9ab47d74-a9d4-415c-9538-45cb30f38b84/';
    scopes = ['api://admin.clubcast.co.uk/access'];
    break;
default:
    // Dev
    clientId = '7b117aa8-7bef-48ed-8089-cbc4e98ba7d2';
    authority = 'https://login.microsoftonline.com/9ab47d74-a9d4-415c-9538-45cb30f38b84/';
    scopes = ['api://admindev.clubcast.co.uk/access'];
    break;
}

const loginRequest = {scopes: ['openid', 'email', 'profile', 'offline_access', ...scopes]};

const msalInstance = new PublicClientApplication({
    auth: {
        clientId,
        authority,
        redirectUri: '/',
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: 'sessionStorage',
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
});

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const ANONYMOUS_USER = {loading: false, user: null, username: null};

const GET_USER_QUERY = gql`
query {
    user {
        id
        email
        can_administer
        can_edit_on_call
    }
}
`

const SET_INVITE_MUTATION = gql`
mutation {
    acceptInvite {
        id
        email
        can_administer
        can_edit_on_call
    }
}`;

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext).auth;
}

export function useAuthError() {
    return useContext(AuthContext).error;
}

export function useLogin() {
    const login = useContext(AuthContext).login;

    return useCallback(() => {
        login(InteractionType.Redirect, loginRequest);
    }, [login]);
}

export function useLogout() {
    const { instance } = useMsal();

    return useCallback(() => {
        // To skip server logout and just do local, handle the redirect callback and return false
        instance.logoutRedirect();
    }, [instance]);
}

export function withAuth(Component, permission = '') {
    return function (props) {
        const auth = useAuth();
        const location = useLocation();
        if (auth.loading) {
            return (
                <div className="auth">
                    <Loader loading={true} />
                </div>
            );
        }
        if (!auth.user && !auth.username) {
            if (process.env.NODE_ENV === 'development') {
                console.log('Navigating to login due to auth required', auth.user);
            }
            return <Navigate to={'/auth/login?return=' + encodeURIComponent(location.pathname)} />;
        }
        if (!auth.user || (permission !== '' && !auth.user[`can_${permission}`])) {
            if (process.env.NODE_ENV === 'development') {
                console.log('Access denied due to permission missing', auth.user, permission);
            }
            return <AccessDenied />;
        }
        return <Component {...props} />;
    }
}

function AuthInner(props) {
    const client = useApolloClient();
    const account = useAccount();
    const setAccessToken = useAccessTokenCallback();
    const [auth, setAuth] = useState({...ANONYMOUS_USER, loading: true});
    const [error, setError] = useState(null);
    const { login, acquireToken, error: msalError } = useMsalAuthentication(InteractionType.Silent, loginRequest);
    const msal = useMsal();

    useEffect(() => {
        if (msal.inProgress !== InteractionStatus.None) {
            return;
        }

        if (process.env.NODE_ENV === 'development') {
            if (account) {
                console.log('User detected', account);
            } else {
                console.log('Anonymous user detected');
            }
        }

        if (!account) {
            setAuth({...ANONYMOUS_USER});
            return;
        }

        let cancelled = false;

        async function updateAuthData() {
            try {
                const response = await acquireToken(InteractionType.Redirect, loginRequest);
                if (cancelled) {
                    return;
                }

                setAccessToken(response.accessToken);

                const result = await client.query({query: GET_USER_QUERY, fetchPolicy: 'no-cache'});
                if (cancelled) {
                    return;
                }

                if (!result.data.user?.id) {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('User does not exist', account);
                    }

                    if (!result.data.user) {
                        setAuth({...ANONYMOUS_USER, username: account.username});
                        return;
                    }

                    if (process.env.NODE_ENV === 'development') {
                        console.log('User is invited', account);
                    }

                    const mutationResult = await client.mutate({mutation: SET_INVITE_MUTATION});
                    if (cancelled) {
                        return;
                    }

                    result.data.user = mutationResult.data.acceptInvite;
                }

                if (process.env.NODE_ENV === 'development') {
                    console.log('User successfully loaded', result.data.user);
                }

                setAuth({user: result.data.user, username: account.username});

                // Schedule a refresh
                window.setTimeout(
                    () => {
                        updateAuthData();
                    },
                    Math.max(
                        30000,
                        differenceInMilliseconds(
                            subSeconds(response.expiresOn, 300),
                            new Date()
                        )
                    )
                );
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    msalInstance.acquireTokenRedirect({});
                } else {
                    setAuth({...ANONYMOUS_USER, username: account.username});
                    setError(error.message);
                    return;
                }
            }
        };

        updateAuthData();

        return () => {
            cancelled = true;
        };
    }, [client, account, acquireToken, setAccessToken, msal]);

    useEffect(() => {
        if (!msalError) {
            return;
        }

        if (msalError instanceof InteractionRequiredAuthError) {
            login(InteractionType.Redirect, loginRequest);
        } else {
            setError(msalError.message);
        }
    }, [login, msal, msalError]);

    return (
        <AuthContext.Provider value={{auth, login, error}}>
            {auth.loading || msal.inProgress !== InteractionStatus.None ? (
                <div className="auth">
                    <Loader loading={true} />
                </div>
            ) : (
                props.children
            )}
        </AuthContext.Provider>
    );
}

export function Auth(props) {
    return (
        <MsalProvider instance={msalInstance}>
            <AuthInner {...props} />
        </MsalProvider>
    );
}
