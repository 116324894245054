import React, { useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import Loader from '../../components/util/loader';
import ErrorMessage from '../../components/util/errormessage';
import { withAuth } from '../../components/util/auth';
import { useNavigate } from 'react-router';
import UserForm from '../../components/user/form';

const GET_USER_QUERY = gql`
query ($email: ID!) {
    getUser(email: $email) {
        email
        can_administer
        can_edit_on_call
    }
}
`

const PUT_USER_MUTATION = gql`
mutation ($email: ID!, $telephone_number: String, $can_administer: Boolean!, $can_edit_on_call: Boolean!) {
    putUser(email: $email, telephone_number: $telephone_number, can_administer: $can_administer, can_edit_on_call: $can_edit_on_call) {
        email
    }
}
`

function UserAdd() {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const client = useApolloClient();
    const [putUser, { error: putError, loading: putLoading }] = useMutation(PUT_USER_MUTATION);

    const loading = submitLoading || putLoading;

    return (
        <div className="grid-container match-edit loaderparent">
            <Loader loading={loading} />
            <div className="grid-x grid-padding-y">
                <div className="cell">
                    <ErrorMessage enabled={error || submitError || putError} message={error} />
                    <UserForm
                        loading={loading}
                        button-label="Add"
                        onError={setError}
                        onSubmit={result => {
                            setSubmitLoading(true);
                            setSubmitError(null);
                            client.query({
                                query: GET_USER_QUERY,
                                fetchPolicy: 'no-cache',
                                variables: {
                                    email: result.email.toLowerCase()
                                }
                            }).then(checkResult => {
                                if (checkResult.data.getUser) {
                                    // Already exists
                                    setSubmitError('The specified user already exists');
                                    throw new Error('User already exists');
                                }
                                return putUser({
                                    variables: result
                                });
                            }).then(() => {
                                setSubmitLoading(false);
                                navigate('/users');
                            }, () => {
                                // Ignore errors in Promise - allow render to display it
                                setSubmitLoading(false);
                            });
                        }} />
                </div>
            </div>
        </div>
    );
}

export default withAuth(UserAdd, 'administer');
